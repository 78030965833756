@import '../../../css/variables';

.booking-details-modal {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;

  .content {
    > div.row {
      margin-top: 33px;
    }

    .details-column {
      font-size: 14px;
      line-height: 20px;

      .item {
        display: flex;

        .title {
          color: $viking-dark-gray;
        }

        .red-value {
          color: $viking-red;
        }
      }
    }
  }

  .btn {
    height: 40px;
    margin: 20px 14px;
  }

  .comment-history {
    width: 100%;
    height: 210px;
    flex-flow: row wrap;
    flex-direction: row;
    overflow-y: scroll;
    border: 1px solid $viking-medium-gray;
    background-color: $viking-light-gray;
    margin: 25px 0 0 0;
    padding: 0;

    * {
      width: 100%;
      flex-grow: 1;
    }

    .title {
      text-align: left;
      padding: 7px;
      border-bottom: 1px solid $viking-medium-gray;
      font-size: 14px;
      font-weight: bold;
    }

    .previous-comments {
      padding: 8px 15px;
      text-align: left;

      .item {
        font-size: 14px;

        .value {
          color: $viking-dark-gray;
        }
      }
    }
  }

  .comment-input-box {
    width: 100%;
    border: 1px solid $viking-medium-gray;
    margin: 0;
    padding: 0;

    .input {
      width: 100%;
      background-color: $viking-white;

      textarea {
        width: 100%;
        border: none;
        padding: 10px;
        box-sizing: border-box;
      }
    }
  }

  .refund-comment {
    text-align: left;
    padding-left: 12px;
    font-size: 14px;
    line-height: 20px;

    .value {
      color: $viking-dark-gray;
    }
  }

  .invoice-number {
    font-size: 18px;
    line-height: 24px;
    color: $viking-navy-blue;
    margin: 0;
    font-weight: bold;
  }

  .booking-status {
    margin: 0 !important;
    font-size: 14px;
    font-weight: bold;
  }

  .loader-container {
    margin: 20px 0;
  }
}
