@import '../../../css/variables';

.history-filters-container {
  display: flex;

  .results-msg {
    font-size: 12px;
    text-align: left;
    padding-top: 26px;
    width: 143px;
    color: $viking-dark-gray;
  }

  .table-filters {
    display: flex;
    margin: 15px 0 0 0;
    justify-content: space-between;
    width: 100%;

    .select-section {
      flex-grow: 2;
      font-size: 12px;
      margin: 10px;
      width: auto;

      .select__single-value {
        margin-top: 16px !important;
      }

      .select-custom-control,
      .select__control {
        height: 40px !important;
      }

      .form-control-placeholder {
        line-height: 40px;
        font-size: 12px;

        &.float-label {
          margin-top: -9px !important;
        }
      }

      .select__value-container {
        height: 41px !important;
      }
    }

    .input-text-section {
      height: 40px;
      font-size: 12px;
      margin: 10px 10px 10px 0;
      flex-grow: 0.75;
      .form-control.with-placeholder {
        height: 40px;
        padding: 9px 6px 0 13px;
      }
    }
  }

  .filter-button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    margin-top: 15px;
    position: relative;

    &-alert {
      position: absolute;
      top: 40px;
      left: 15px;
      font-size: 11px;
      width: 15vw;
      color: white;
      background-color: $viking-red;
      padding: 5px;
      z-index: 100;
      display: none;

      &.show {
        display: block;
      }
    }
  }
}
