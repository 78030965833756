@import '../../node_modules/bootstrap/dist/css/bootstrap.css';
// @import 'node_modules/@viking-eng/css/lib/viking';
@import '_variables.scss';
@import 'animations.scss';

html,
body,
#root {
  height: 100%;
}

body {
  background-color: $viking-royal-blue;
}

.app-content {
  background-color: $viking-white;
  padding-bottom: 40px;
  text-align: center;
}

h5 {
  padding-top: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  @media screen and (min-width: 1200px) {
    max-width: 90%;
  }
}

.clickable {
  cursor: pointer;
}

.color-viking-red {
  color: $viking-red;
}

.color-viking-green {
  color: $viking-green;
}

.color-viking-gray {
  color: $viking-gray;
}

.hover-btn:hover {
  color: white;
}
