@import '../../../css/variables';

$table-row-width: 2% 2.5% 2.5% 4.5% 5% 7.5% 6% 6% 12% 3% 5% 9.5% 7.5% 8% 9% 8%
  3%;

.refund-table {
  cursor: default;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  table {
    display: table;
    flex-direction: column;
    border: 1px solid #979797;

    thead {
      width: 100%;
      display: block;
      background-color: $viking-light-gray;

      tr {
        display: flex;
        width: 100%;

        td {
          padding: 0;
          margin: 0;
          font-size: 12px;
          border: 1px solid #cccccc;
          text-align: center;
          vertical-align: middle;

          @for $i from 1 through 17 {
            &:nth-child(#{$i}) {
              width: nth($table-row-width, $i);
            }
          }
        }
      }
    }

    tbody {
      display: block;
      overflow-y: scroll;
      height: 450px;
      width: 100%;

      tr {
        display: flex;
        width: 100%;

        &.highlighted {
          background-color: rgba(90, 172, 0, 0.15);

          &:hover {
            background-color: rgba(90, 172, 0, 0.2);
          }
        }

        &.grouped-summary-line {
          font-weight: 700;
        }

        &.hidden {
          display: none;
        }

        td {
          font-size: 12px;
          padding: 0px;
          margin: 0px;
          overflow: hidden;

          &.name-cell {
            text-align: left;
            padding: 0px 10px 0px 10px;
          }

          @for $i from 1 through 17 {
            &:nth-child(#{$i}) {
              width: nth($table-row-width, $i);
            }
          }

          .expand-icon {
            margin-right: 3px;
          }
        }
      }

      .single-booking-entry {
        background-color: $viking-light-gray;

        &:hover {
          background-color: darken($viking-light-gray, 10%);
        }
      }

      .single-booking-entry,
      .grouped-summary-line {
        &.highlighted {
          background-color: rgba(90, 172, 0, 0.35) !important;

          &:hover {
            background-color: rgba(90, 172, 0, 0.45) !important;
          }
        }
      }
    }

    .reviewCell {
      display: flex;
      justify-content: space-evenly;
      margin: 2px;
    }

    .money-col {
      padding: 0px 10px;
      text-align: right;
    }

    .negative {
      color: $viking-red;
    }
  }

  .header-messages {
    display: flex;
    justify-content: space-between;

    .results-msg {
      font-size: 12px;
      text-align: left;
    }
  }

  .table-buttons {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;

    .approved-bookings-count {
      margin-left: 30px;
    }
  }

  .clickable-icon::after {
    content: '';
    padding: 20px;
    position: absolute;
    left: -25px;
    top: -25px;
  }

  .locktext {
    font-size: 12px;
  }

  .hidden-button {
    display: none;
  }

  .click-icon {
    pointer-events: none;
  }
}
