@import '../../../../css/variables';

.params-filters-container {
  display: flex;

  //remove inside of arrows button
  input[type='date']::-webkit-inner-spin-button {
    display: none;
  }

  //remove outside of arrows button
  input[type='date']::-webkit-outside-spin-button {
    display: none;
  }

  .table-filters {
    display: flex;
    margin: 15px 0 0 0;
    justify-content: space-between;
    width: 100%;

    .date-select {
      font-size: 12px;
      margin: 10px;
      padding: 10px;
      width: 225px;
      color: $viking-dark-gray;
    }

    .date-label {
      display: inline-block;
      padding: 0px;
      margin: 0px;
      font-size: 12px;
    }

    .form-error-message {
      color: $viking-red;
      font-size: 12px;
    }

    .history-btn {
      color: white;
      background-color: $viking-royal-blue;
      width: 100px;
      height: 35px;
      font-size: 12px;
      margin: 35px 5px;
      padding: 0px;
    }
  }
}
