@import '../../../css/variables';

.filters-container {
  display: flex;
  margin-bottom: 15px;

  .table-filters {
    display: flex;
    flex-direction: column;
    margin: 15px 0 0 0;
    justify-content: space-between;
    width: 100%;

    .search-input-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0px 10px;
      width: 100%;
    }

    .select-section {
      flex-grow: 2;
      font-size: 12px;
      margin: 10px;
      width: auto;

      .select__single-value {
        margin-top: 16px !important;
      }

      .select-custom-control,
      .select__control {
        height: 40px !important;
      }

      .form-control-placeholder {
        line-height: 40px;
        font-size: 12px;

        &.float-label {
          margin-top: -9px !important;
        }
      }

      .select__value-container {
        height: 41px !important;
      }
    }

    .input-text-section {
      height: 40px;
      font-size: 12px;
      margin: 10px 10px 10px 0;
      flex-grow: 0.75;
      .form-control.with-placeholder {
        height: 40px;
        padding: 9px 6px 0 13px;
      }
    }
  }

  .button-container {
    display: flex;
    flex-direction: column;
    justify-items: space-around;

    .buttons-only {
      display: flex;
      justify-content: space-around;
      padding: 0px;
      margin: 0px;
    }

    .results-msg {
      display: flex;
      margin: 0px;
      padding: 10px;
      font-size: 12px;
      text-align: center;
      color: $viking-dark-gray;
    }
    .filter-button {
      align-items: center;
      background-color: transparent;
      border: none;
      position: relative;
      height: 50px;

      &-alert {
        position: absolute;
        top: 40px;
        left: 15px;
        font-size: 11px;
        width: 15vw;
        color: white;
        background-color: $viking-red;
        padding: 5px;
        z-index: 100;
        display: none;

        &.show {
          display: block;
        }
      }
    }
  }
}
