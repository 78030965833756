@import '../../../css/variables';

.header {
  .right-menu {
    .icon-panel-wrapper {
      float: right;
      padding-top: 0;
    }

    button {
      color: $viking-white;
      font-size: 16px;

      .label {
        margin-right: 4px;
        text-transform: uppercase;
      }
    }
  }
}
