@import '../../../css/variables';

.info-modal {
  .content {
    margin-top: 36px;
    .summary-row {
      width: 62%;
      margin: 0 auto;
    }

    .details-column {
      font-size: 14px;
      line-height: 20px;
      .item {
        display: flex;
        .title {
          color: $viking-dark-gray;
        }
        .red-value {
          color: $viking-red;
        }
      }
    }

    .buttons-row {
      margin-top: 36px;
    }

    .top-spacing {
      margin-top: 36px;
    }
  }

  .btn {
    margin: 0 40px;
  }
}
