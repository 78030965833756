@import '../../../css/variables';

$table-row-width: 4% 5% 4% 5% 11% 4% 5% 6% 7% 7% 7% 6% 9% 9% 12%;

.error-table {
  padding-top: 20px;

  table {
    display: table;
    flex-direction: column;
    border: 1px solid #979797;

    thead {
      width: 90vw;
      display: block;
      background-color: $viking-light-gray;

      tr {
        display: flex;
        width: 100%;

        td {
          padding: 0;
          margin: 0;
          font-size: 12px;
          border: 1px solid #cccccc;
          text-align: center;
          vertical-align: middle;

          @for $i from 1 through 15 {
            &:nth-child(#{$i}) {
              width: nth($table-row-width, $i);
            }
          }
        }
      }
    }
  }
  tbody {
    display: block;
    overflow-y: scroll;
    height: 450px;
    width: 100%;

    tr {
      display: flex;
      width: 100%;

      td {
        font-size: 12px;
        padding: 0px;
        margin: 0px;
        overflow: hidden;

        @for $i from 1 through 15 {
          &:nth-child(#{$i}) {
            width: nth($table-row-width, $i);
          }
        }
      }
    }
  }
}
