@import '../../../css/variables';

$table-row-width: 6% 5% 4% 6% 6% 5% 6% 5% 7% 6.3% 6% 6% 10% 3.5% 4.5% 7% 7%;

.history {
  padding-top: 20px;
}

.header-messages {
  display: flex;
  justify-content: space-between;

  .results-msg {
    font-size: 12px;
    text-align: left;
  }
}

table {
  display: table;
  flex-direction: column;
  border: 1px solid #979797;
  padding-top: 20px;

  thead {
    width: 100%;
    display: block;
    background-color: $viking-light-gray;

    tr {
      display: flex;
      width: 100%;

      td {
        padding: 0;
        margin: 0;
        font-size: 12px;
        border: 1px solid #cccccc;
        text-align: center;
        vertical-align: middle;

        @for $i from 1 through 17 {
          &:nth-child(#{$i}) {
            width: nth($table-row-width, $i);
          }
        }
      }
    }
  }
  tbody {
    display: block;
    overflow-y: scroll;
    height: 450px;
    width: 100%;

    tr {
      display: flex;
      width: 100%;

      td {
        font-size: 12px;
        padding: 0px;
        margin: 0px;
        overflow: hidden;

        @for $i from 1 through 17 {
          &:nth-child(#{$i}) {
            width: nth($table-row-width, $i);
          }
        }
      }
    }
  }
}
